var firebaseConfig = {
  apiKey: "AIzaSyDdxTfcJ72mxEKlNk2YyPRuyRa4lERcTWs",
  authDomain: "news-5b643.firebaseapp.com",
  databaseURL: "https://news-5b643.firebaseio.com",
  projectId: "news-5b643",
  storageBucket: "news-5b643.appspot.com",
  messagingSenderId: "624152053587",
  appId: "1:624152053587:web:ced2b38cca71bc3d80c3a6"
};

export default firebaseConfig;
